@import url(https://fonts.googleapis.com/css2?family=Markazi+Text:wght@700&display=swap);
html {
    height: 100%;
    width: 100%;
  }
  
  body {
    margin: 0;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Markazi Text", sans-serif, serif;
  }
.home {
    max-width: 100%;
    height: 100%;
}

.home__img-background {
    height: 100vh;
}
.home__img-background img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

.home__description__summary {
    padding: 1rem;
}

@media screen and (min-width: 700px) {
    .home__description {
        font-size: 1.25rem;
    }
}
.coding-hobbies {
    padding: 3rem;
    align-items: center;
    text-align: center;
    background-color: #e1e1e1;
    display: flex;
    flex-direction: column;
}

.coding-hobbies:nth-child(2n) {
    background-color: #fff;
}

.coding-hobbies__header {
    font-size: 3rem;
    padding: 3rem;
}

.coding-hobbies__content {
    display: flex;
    flex-direction: column;
}

h2 {
    font-size: 2rem;
    margin: auto;
    max-width: 90%;
}

@media screen and (min-width: 1150px) {
    .coding-hobbies {
        padding: 5rem;
        display: flex;
    }

    .coding-hobbies__content {
        display: flex;
        flex-direction: row;
    }
    .coding-hobbies__content > * {
        flex-basis: 100%;
    }
}
.description-card {
    padding: 1.5rem;
}
.description-card__content {
    display: flex;
}
.description-card__content > * {
    flex-basis: 100%;
}

@media screen and (min-width: 700px) {
    .description-card__content {
        align-items: center;
        padding: 3rem;
    }
}
.footer {
    max-width: 100%;
    background-color: #1a1a1a;
    padding: 3rem;
}

.social-media {
    display: flex;
    max-width: 100%;
}

.footer .social-media ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    width: 100%;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

@media screen and (min-width: 700px) {
    .footer .social-media ul {
        width: 25%;
    }
}

