.footer {
    max-width: 100%;
    background-color: #1a1a1a;
    padding: 3rem;
}

.social-media {
    display: flex;
    max-width: 100%;
}

.footer .social-media ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    width: 100%;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

@media screen and (min-width: 700px) {
    .footer .social-media ul {
        width: 25%;
    }
}