.home {
    max-width: 100%;
    height: 100%;
}

.home__img-background {
    height: 100vh;
}
.home__img-background img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

.home__description__summary {
    padding: 1rem;
}

@media screen and (min-width: 700px) {
    .home__description {
        font-size: 1.25rem;
    }
}